import React from 'react';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"

const hostName = window.location.hostname.toString().replace(/^(docs-api).(.*)$/gi, "$2");

const docApiUrl = (hostName.indexOf('localhost') + 1)
    ? 'http://api.new-log-system.loc/docs/v2/rest-api.json'
    : `${window.location.protocol}//api.${hostName}/docs/v2/rest-api.json`;

export default function App () {
  return (
      <SwaggerUI url={docApiUrl} />
  );
}
